var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"table",attrs:{"value":_vm.value,"height":_vm.height,"loading":_vm.localLoading,"loading-text":_vm.loadingText,"headers":_vm.columns,"items":_vm.items,"item-key":_vm.itemKey,"page":_vm.page,"sort-by":_vm.localSortBy,"sort-desc":_vm.localSortDesc,"items-per-page":_vm.localPerPage,"server-items-length":_vm.localTotalServerItems,"show-select":_vm.showSelect,"hide-default-footer":_vm.hideDefaultFooter,"footer-props":{
        prevIcon: 'fal fa-chevron-left',
        nextIcon: 'fal fa-chevron-right',
        itemsPerPageOptions: [5, 10, 15, 20, 50, 100, 500, 1000, -1]
    },"multi-sort":_vm.multiSort,"show-group-by":_vm.showGroupBy,"custom-group":_vm.groupItems,"group-by":_vm.localGroupBy,"fixed-header":_vm.fixedHeader,"dense":_vm.dense},on:{"update:page":[function($event){_vm.page=$event},_vm.handlePageUpdated],"update:sortBy":function($event){_vm.localSortBy=$event},"update:sort-by":[function($event){_vm.localSortBy=$event},_vm.handleSortByUpdated],"update:sortDesc":function($event){_vm.localSortDesc=$event},"update:sort-desc":[function($event){_vm.localSortDesc=$event},_vm.handleSortByUpdated],"update:itemsPerPage":function($event){_vm.localPerPage=$event},"update:items-per-page":[function($event){_vm.localPerPage=$event},_vm.handleItemsPerPageUpdated],"update:options":_vm.handleOptionsUpdated,"input":_vm.handleInput},scopedSlots:_vm._u([(! _vm.hideHeader)?{key:"top",fn:function(){return [_c('div',{staticClass:"tw-px-4 tw-py-4 tw-flex tw-items-center tw-justify-between"},[(! _vm.hideCaption)?_c('h3',{staticClass:"tw-mb-0 font-weight-regular"},[_vm._v(_vm._s(_vm.caption || _vm.$route.meta.title || _vm.$route.meta.label))]):_vm._e(),_c('v-spacer'),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end"},[(_vm.searchable)?_c('v-text-field',{attrs:{"solo-inverted":"","flat":"","hide-details":"","label":"Search","dense":"","clearable":"","clear-icon":"fal fa-times-circle fa-fw","prepend-inner-icon":"fal fa-search","color":"white"},on:{"input":_vm.search}}):_vm._e(),_vm._t("actions"),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"min-width":300,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [(_vm.filtersUrl)?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","rounded":"","outlined":""},on:{"click":_vm.getFilters}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":"","dense":""}},[_vm._v("far fa-filter fa-sm")]),_vm._v(" Filter ")],1):_vm._e()]}}],null,false,564690165),model:{value:(_vm.showFiltersMenu),callback:function ($$v) {_vm.showFiltersMenu=$$v},expression:"showFiltersMenu"}},[_c('v-list',{staticClass:"tw-py-0"},[(_vm.gettingFilters)?_c('v-list-item',[_c('v-list-item-title',[_vm._v("Loading...")])],1):_c('v-list-item',{staticClass:"tw-px-0"},[_c('v-card',{staticClass:"tw-w-full",attrs:{"flat":""}},[_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.filters,"item-text":"label","item-value":"column","label":"Filter By"},on:{"change":_vm.handleFilterChange}}),(_vm.selectedFilter && _vm.selectedFilter.input_type === 'autocomplete')?_c('v-autocomplete',{attrs:{"items":_vm.selectedFilter.options,"item-text":_vm.selectedFilter.item_text,"item-value":_vm.selectedFilter.item_value,"label":"Value","multiple":"","chips":"","deletable-chips":""},model:{value:(_vm.selectedFilterValue),callback:function ($$v) {_vm.selectedFilterValue=$$v},expression:"selectedFilterValue"}}):_vm._e(),(_vm.selectedFilter && _vm.selectedFilter.input_type === 'combobox')?_c('v-combobox',{attrs:{"label":"Value","multiple":"","chips":"","deletable-chips":""},model:{value:(_vm.selectedFilterValue),callback:function ($$v) {_vm.selectedFilterValue=$$v},expression:"selectedFilterValue"}}):_vm._e(),(_vm.selectedFilter && _vm.selectedFilter.input_type === 'date')?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Select Range","readonly":""},model:{value:(_vm.dateRangeFilterValueText),callback:function ($$v) {_vm.dateRangeFilterValueText=$$v},expression:"dateRangeFilterValueText"}},'v-text-field',attrs,false),on))]}}],null,false,2612561643),model:{value:(_vm.showFilterDateMenu),callback:function ($$v) {_vm.showFilterDateMenu=$$v},expression:"showFilterDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},on:{"change":function($event){_vm.showFilterDateMenu = false}},model:{value:(_vm.selectedFilterValue),callback:function ($$v) {_vm.selectedFilterValue=$$v},expression:"selectedFilterValue"}})],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showFiltersMenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.applyFilter}},[_vm._v("Apply")])],1)],1)],1)],1)],1),_c('v-btn',{staticClass:"ml-2",attrs:{"small":""},on:{"click":_vm.refresh}},[_c('v-icon',{attrs:{"left":"","dense":""}},[_vm._v("far fa-sync-alt fa-sm")]),_vm._v(" Refresh ")],1)],2)],1),_vm._t("subheader"),(_vm.selectedFilters.length > 0)?_c('div',{staticClass:"tw-p-4 tw-flex tw-items-center"},_vm._l((_vm.selectedFilters),function(filter){return _c('v-chip',{key:filter.id,staticClass:"tw-mr-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.deleteSelectedFilter(filter)}}},[_vm._v(" "+_vm._s(((filter.label) + ": " + (filter.value.title)))+" ")])}),1):_vm._e(),_c('v-divider')]},proxy:true}:null,{key:"item",fn:function(ref){
    var item = ref.item;
    var headers = ref.headers;
    var isSelected = ref.isSelected;
    var select = ref.select;
return [_c('tr',{class:{'grey lighten-3': isSelected},on:{"mouseover":function($event){return _vm.handleMouseoverRow($event)},"mouseout":function($event){return _vm.handleMouseoutRow($event)}}},[_vm._l((headers),function(header,i){return [(header.value !== 'actions')?_c('td',{key:header.value,staticClass:"tw-relative",attrs:{"align":header.align}},[(header.value === 'data-table-select')?_c('v-checkbox',{attrs:{"input-value":isSelected},on:{"click":function($event){$event.stopPropagation();return select(isSelected ? false : true)}}}):_vm._t(header.slotName,function(){return [_vm._v(_vm._s(_vm.leaf(item, header.value)))]},{"item":item}),(i === (headers.length - 1))?_c('div',{staticClass:"actions tw-hidden tw-absolute tw-items-center tw-divide-x tw-divide-gray-400 tw-top-0 tw-right-0"},[_vm._l((_vm.actions),function(action){return [((action.isEligible && action.isEligible(item)) || ! action.isEligible)?_c('div',{key:action.label,staticClass:"mr-2 pl-2 tw-flex-grow"},[(action.handler)?_c('a',{staticClass:"d-flex align-center tw-text-blue-600 hover:tw-text-blue-800",on:{"click":function($event){return action.handler(item)}}},[_c('span',{class:action.icon}),_c('span',{staticClass:"ml-1 tw-flex-grow tw-whitespace-nowrap"},[_vm._v(_vm._s(action.label))])]):_vm._e(),(action.href)?_c('a',{staticClass:"d-flex align-center tw-text-blue-600 hover:tw-text-blue-800",attrs:{"href":action.href(item),"target":"_blank"}},[_c('span',{class:action.icon}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(action.label))])]):_vm._e(),(action.dropdown)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"d-flex align-center tw-text-blue-600 hover:tw-text-blue-800"},'a',attrs,false),on),[_c('span',{class:action.icon}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(action.label))])])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((action.dropdown(item)),function(item,i){return _c('v-list-item',{key:i,attrs:{"href":item.href,"target":"_blank"}},[(item.icon)?_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.label)}})],1)],1)}),1)],1):_vm._e()],1):_vm._e()]})],2):_vm._e()],2):_vm._e()]})],2)]}},_vm._l((_vm.columns),function(column){return {key:column.slotName,fn:function(item){return [_vm._t(("item." + (column.value)),null,{"item":item})]}}}),{key:"group.header",fn:function(ref){
    var group = ref.group;
    var groupBy = ref.groupBy;
    var headers = ref.headers;
    var toggle = ref.toggle;
    var isOpen = ref.isOpen;
    var items = ref.items;
return [_vm._t("group-header",function(){return [_c('th',{ref:group,staticClass:"tw-cursor-pointer tw-sticky",staticStyle:{"background-color":"#eee","top":"48px","z-index":"1"},attrs:{"colspan":headers.length},on:{"click":toggle}},[_c('div',{staticClass:"tw-flex tw-items-center"},[(isOpen)?_c('span',{staticClass:"fal fa-chevron-up fa-sm"}):_c('span',{staticClass:"fal fa-chevron-down fa-sm"}),_c('div',{staticClass:"ml-2 tw-flex-1"},[_vm._t("group-header-label",function(){return [_vm._v(" "+_vm._s(group)+" ")]},{"item":{ group: group, groupBy: groupBy, items: items }})],2)])])]},{"item":{ group: group, groupBy: groupBy, headers: headers, toggle: toggle, isOpen: isOpen, items: items }})]}},{key:"body.append",fn:function(item){return [_vm._t("body.append",null,{"item":item})]}},{key:"footer",fn:function(item){return [_vm._t("footer",null,{"data":item})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }